import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

let app;
let analytics;

export const initializeFirebase = () => {
  if (!app) {
    try {
      app = initializeApp(firebaseConfig);
      analytics = getAnalytics(app);
    } catch (error) {
      console.error('Error initializing Firebase:', error);
    }
  }
};

export const getFirebaseApp = () => {
  if (!app) {
    console.error('Firebase app not initialized. Initializing now...');
    initializeFirebase();
  }
  return app;
};

export const getFirebaseAnalytics = () => {
  if (!analytics) {
    console.error('Firebase analytics not initialized. Initializing Firebase...');
    initializeFirebase();
  }
  return analytics;
};
