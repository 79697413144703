export const PetventureActions = Object.freeze({
  // adventure tab action
  ADD_ADVENTURES_LIST: 'ADD_ADVENTURES_LIST',
  ADD_MORE_ADVENTURES_LIST: 'ADD_MORE_ADVENTURES_LIST',
  SELECT_ADVENTURE: 'SELECT_ADVENTURE',

  // Friend tab action
  ADD_FRIENDS_LIST: 'ADD_FRIENDS_LIST',
  SELECT_FRIEND: 'SELECT_FRIEND',
  
  // Petventure tab action
  PETVENTURE_TAB_CHANGE: 'PETVENTURE_TAB_CHANGE',
  
  // Clear store
  CLEAR_PETVENTURE: 'CLEAR_PETVENTURE',
})
