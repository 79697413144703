export const DEVICE_TYPE = Object.freeze({
  DESKTOP: "DESKTOP",
  MOBILE: "MOBILE",
  TABLET: "TABLET",
});

export const PAGE_LOCATION = Object.freeze({
  HOME_PAGE: "homepage",
  NAVBAR: "navbar",
  PROFILE_PAGE: "profile_page",
  EDIT_PROFILE_PAGE: "edit_profile_page",
  EDIT_PROFILE_PICTURE_PAGE: "edit_profile_picture_page",
  TERMS_OF_USE_PAGE: "terms_of_use_page",
  PRIVACY_POLICY_PAGE: "privacy_policy_page",
  PET_CREATION: "pet_creation",
  PET_DETAIL: "pet_details",
  BLOG: "blog",
  BLOG_VIEW: "blog_view",
  SHOP: "shop",
  SHOP_CATEGORY: "shop_category",
  SHOP_SEARCH: "shop_search",
  PRODUCT_VIEW: "product_view",
  CART: "cart",
  CHECKOUT: "checkout",
  CONFIRM: "confirm",
  ORDER_DETAILS: "order_details",
  EVENT: "event",
  LOGIN: "login",
  REGISTER: "register",
});

export const BOOK_PAGE = Object.freeze({
  LEFT: "LEFT",
  RIGHT: "RIGHT",
});

export const PETVENTURE_TAB_KEY = Object.freeze({
  ADVENTURES: "ADVENTURES",
  FRIENDS: "FRIENDS",
});
