import { PETVENTURE_TAB_KEY } from "../../constants/enum";
import { PetventureActions } from "../actions/PetventureActions";

const initialState = {
  adventures: [],
  adventureSelected: null,

  friends: [],
  friendSelected: null,

  petventureActiveTab: PETVENTURE_TAB_KEY.ADVENTURES,
};

export function PetventureReducer(state = initialState, action) {
  // adventure tab action
  if (action.type === PetventureActions.ADD_ADVENTURES_LIST) {
    return { ...state, adventures: action.payload };
  }
  if (action.type === PetventureActions.ADD_MORE_ADVENTURES_LIST) {
    return { ...state, adventures: [...state.adventures, ...action.payload] };
  }
  if (action.type === PetventureActions.SELECT_ADVENTURE) {
    return {
      ...state,
      adventureSelected: action.payload,
      friendSelected: null,
    };
  }

  // Friend tab action
  if (action.type === PetventureActions.ADD_FRIENDS_LIST) {
    return { ...state, friends: action.payload };
  }
  if (action.type === PetventureActions.SELECT_FRIEND) {
    return {
      ...state,
      adventureSelected: null,
      friendSelected: action.payload,
    };
  }

  // Petventure tab action
  if (action.type === PetventureActions.PETVENTURE_TAB_CHANGE) {
    return {
      ...state,
      petventureActiveTab: action.payload,
      adventureSelected: null,
      friendSelected: null,
    };
  }

  // Clear store
  if (action.type === PetventureActions.CLEAR_PETVENTURE) {
    return { ...initialState };
  }

  return state;
}
