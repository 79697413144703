import React, {lazy, Suspense} from "react";
import {Switch, Route, Redirect, withRouter} from "react-router-dom";
import {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';

import Nav from './views/partials/Nav';
import {checkAutoLogin} from "./services/AuthService";
import {isAuthenticated} from './Store/selectors/AuthSelectors';

import ReactGA from 'react-ga';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

Sentry.init({
    environment: (process.env.ENVIRONMENT === "DEV" ? "development" : "production"),
    release: "the-paradise-beyond-frontend@" + process.env.PACKAGE_VERSION,
  dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: process.env.SENTRY_SAMPLE_RATE,
});

function App(props) {

    const dispatch = useDispatch();
    useEffect(() => {
        checkAutoLogin(dispatch, props.history);
    }, [dispatch, props]);

    let routes = (
        <Switch>
            <Route path='/reset-password/:token' component={lazy(() => import(`./views/auth/ResetPassword`))}/>
            <Route path='/forgot-password' component={lazy(() => import(`./views/auth/ForgotPassword`))}/>
            <Route path='/register' component={lazy(() => import(`./views/auth/Registration`))}/>
            <Route path='/:partner/commission' component={lazy(() => import(`./views/auth/Registration`))}/>
            <Route path='/partners/:partner' component={lazy(() => import(`./views/partners/Cache`))}/>
            <Route path='/login' component={lazy(() => import(`./views/auth/Login`))}/>
            <Route path='/' component={lazy(() => import(`./views/HomePage`))} exact/>
            <Route path='/pets/ascend' exact component={lazy(() => import(`./views/pets/create`))}/>
            <Route path='/discover' component={lazy(() => import(`./views/Discover`))} exact/>
            <Route path='/pets/:id/view' exact component={lazy(() => import(`./views/pets/View`))}/>
            <Route path='/pets/:id/guest-book' exact component={lazy(() => import(`./views/pets/GuestBook`))}/>
            <Route path='/termsofuse' exact component={lazy(() => import(`./views/TermsOfUse`))}/>
            <Route path='/privacypolicy' exact component={lazy(() => import(`./views/PrivacyPolicy`))}/>
			<Route path='/blog' exact component={lazy(() => import(`./views/blog`))}/>
            <Route path='/blog/:id/view' component={lazy(() => import(`./views/blog/View`))}/>
			<Route path='/shop' exact component={lazy(() => import(`./views/shop`))}/>
            <Route path='/shop/:id/product' component={lazy(() => import(`./views/shop/Product`))}/>
            <Route path='/shop/search' component={lazy(() => import(`./views/shop/Search`))}/>
            <Route path='/shop/:id/view' component={lazy(() => import(`./views/shop/View`))}/>
			<Route path='/shop/cart' component={lazy(() => import(`./views/shop/Cart`))}/>
			<Route path='/shop/checkout' component={lazy(() => import(`./views/shop/Checkout`))}/>
            <Route path='/shop/confirm' component={lazy(() => import(`./views/shop/Confirm`))}/>
			<Route path='/shop/:id/order' component={lazy(() => import(`./views/shop/Myorder`))}/>
            <Route path='/event' component={lazy(() => import(`./views/event`))}/>
            <Redirect from='/' to='/login'/>
            <Route path='*' component={lazy(() => import(`./views/NotFound`))}/>
        </Switch>
    );

    if (props.isAuthenticated) {
        routes = (
            <Switch>
                <Route  path='/' exact component={lazy(() => import(`./views/HomePage`))}/>
                <Route  path='/termsofuse' exact component={lazy(() => import(`./views/TermsOfUse`))}/>
                <Route path='/pets/ascend' exact component={lazy(() => import(`./views/pets/create`))}/>
                <Route path='/discover' component={lazy(() => import(`./views/Discover`))}/>
                <Route path='/karma' component={lazy(() => import(`./views/karma`))}/>
                <Route path='/pets' exact component={lazy(() => import(`./views/pets`))}/>
                <Route path='/pets/:id/view' exact component={lazy(() => import(`./views/pets/View`))}/>
                <Route path='/pets/:id/guest-book' exact component={lazy(() => import(`./views/pets/GuestBook`))}/>
                <Route path='/pets/:id/book-of-petventures' exact component={lazy(() => import(`./views/pets/Petventures`))}/>
                <Route path='/profile/edit/photo' component={lazy(() => import(`./views/profile/ProfilePhoto`))}/>
                <Route path='/profile/edit' component={lazy(() => import(`./views/profile/Edit`))}/>
                <Route path='/profile' component={lazy(() => import(`./views/profile`))}/>
                <Route path='/privacypolicy' exact component={lazy(() => import(`./views/PrivacyPolicy`))}/>
				<Route path='/blog' exact component={lazy(() => import(`./views/blog`))}/>
                <Route path='/blog/:id/view' component={lazy(() => import(`./views/blog/View`))}/>
				<Route path='/shop' exact component={lazy(() => import(`./views/shop`))}/>
				<Route path='/shop/:id/product' component={lazy(() => import(`./views/shop/Product`))}/>
                <Route path='/shop/search' component={lazy(() => import(`./views/shop/Search`))}/>
                <Route path='/shop/:id/view' component={lazy(() => import(`./views/shop/View`))}/>
				<Route path='/shop/cart' component={lazy(() => import(`./views/shop/Cart`))}/>
				<Route path='/shop/checkout' component={lazy(() => import(`./views/shop/Checkout`))}/>
				<Route path='/shop/confirm' component={lazy(() => import(`./views/shop/Confirm`))}/>
				<Route path='/shop/:id/order' component={lazy(() => import(`./views/shop/Myorder`))}/>
                <Route path='/event' component={lazy(() => import(`./views/event`))}/>
                <Redirect from='/' to='/profile'/>
                <Route path='*' component={lazy(() => import(`./views/NotFound`))}/>
                
            </Switch>
        );
    }

    return (
        <div className="petpages">
            <Nav/>
            <div style={{paddingBottom:"10%"}}></div>
            <Suspense fallback={
                <div className="loading">
                    <img src="/images/menubar-navbar-desktop/logo.png" alt="loader" className="loader-img"/>
                </div>
            }>
                {routes}
            </Suspense>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App));
