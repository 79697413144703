import { logEvent } from "firebase/analytics";
import { getFirebaseAnalytics } from "../firebase";

class FirebaseEventLogger {
  constructor() {
    this.analytics = getFirebaseAnalytics();
  }

  firebaseEventLog(eventName, eventParams = {}) {
    logEvent(this.analytics, eventName, eventParams);
  }

  logButtonClick(buttonName, pageLocation) {
    this.firebaseEventLog(buttonName, {
      page_location: pageLocation,
    });
  }

  logPageView(pageName) {
    this.firebaseEventLog("page_view", { page_name: pageName });
  }
}

const firebaseLogger = new FirebaseEventLogger();

export { firebaseLogger };
